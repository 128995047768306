import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getLessonPlanToStudent = createAsyncThunk(
  'studentEnrollment/getLessonPlanToStudent',
  async (_, { getState }) => {
    const {
      menuYear: { selectedYear },
    } = getState();

    try {
      const { data } = await api.get(
        `lesson-plan/academic-year/${selectedYear.id}/student`
      );
      return data.data;
    } catch (error) {
      console.error('Grades API error: ', error.message);
      return [];
    }
  }
);

export const getEnrollment = createAsyncThunk(
  'studentEnrollment/getEnrollment',
  async (_, { getState }) => {
    const {
      menuYear: { selectedYear },
    } = getState();

    try {
      const { data } = await api.get(
        `student-enrollment/academic-year/${selectedYear.id}/student`
      );
      return data.data;
    } catch (error) {
      console.error('Grades API error: ', error.message);
      return [];
    }
  }
);

export const toEnroll = createAsyncThunk(
  'studentEnrollment/toEnroll',
  async (gradeId) => {
    try {
      const response = await api.post('student-enrollment/student', {
        gradeId,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

const studentEnrollmentAdapter = createEntityAdapter({});

export const {
  selectAll: selectStudentEnrollment,
  selectEntities: selectStudentEnrollmentEntities,
  selectById: selectEvaluationById,
} = studentEnrollmentAdapter.getSelectors((state) => state.studentEnrollment);

const studentEnrollmentSlice = createSlice({
  name: 'studentEnrollment',
  initialState: studentEnrollmentAdapter.getInitialState({
    lessonPlans: [],
    enrollments: [],
    maxOccupationModal: {
      show: false,
      data: null,
    },
  }),

  reducers: {
    openConfirmModalModal: (state, { payload }) => {
      state.maxOccupationModal = { show: true, data: payload };
    },
    closeConfirmModalModal: (state) => {
      state.maxOccupationModal = { data: null, show: false };
    },
  },

  extraReducers: {
    [getLessonPlanToStudent.fulfilled]: (state, { payload }) => {
      state.lessonPlans = payload;
    },

    [getEnrollment.fulfilled]: (state, { payload }) => {
      state.enrollments = payload;
    },

    [toEnroll.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        state.enrollments = [...state.enrollments, payload.data];
      }
    },
  },
});

export const { openConfirmModalModal, closeConfirmModalModal } =
  studentEnrollmentSlice.actions;

export default studentEnrollmentSlice.reducer;

import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async ({ currentPage, rowsPerPage }, { getState }) => {
    const {
      notifications: { pagination },
      menuYear: { selectedYear },
    } = getState();

    const { data } = await api.get('school-message', {
      params: {
        academicYearId: selectedYear.id,
        currentPage: currentPage ?? pagination.currentPage,
        rowsPerPage: rowsPerPage ?? pagination.rowsPerPage,
      },
    });

    return data;
  }
);

export const createNotification = createAsyncThunk(
  'notifications/createNotification',
  async (notification, { getState }) => {
    const {
      menuYear: { selectedYear },
    } = getState();

    try {
      const response = await api.post('school-message', {
        ...notification,
        academicYearId: selectedYear.id,
      });
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const updateNotificationStatus = createAsyncThunk(
  'notifications/updateNotificationStatus',
  async ({ status, userId, message }) => {
    try {
      const { data } = await api.patch(`school-message-recipient/${userId}`, {
        status,
      });

      const attRecipients = message.recipients.map((recipient) => {
        if (userId === recipient.id) {
          return data;
        }
        return recipient;
      });

      return {
        success: true,
        data: { ...message, recipients: attRecipients },
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const deleteNotification = createAsyncThunk(
  'notifications/deleteNotification',
  async (id) => {
    try {
      const response = await api.delete(`school-message/${id}`);
      const data = await response.data;
      return {
        success: true,
        data,
      };
    } catch (err) {
      return {
        success: false,
        data: err.response.data,
      };
    }
  }
);

export const getUsers = createAsyncThunk(
  'notifications/getUsers',
  async ({ searchFor }) => {
    const response = await api.get('/user', {
      params: {
        searchFor,
        type: 'INSTRUCTOR',
      },
    });

    return response.data;
  }
);

export const getNotificationInfos = createAsyncThunk(
  'notifications/getNotificationInfos',
  async (id) => {
    try {
      const { data } = await api.get(`school-message/${id}`);
      return { data, success: true };
    } catch (error) {
      return { data: error, success: false };
    }
  }
);

const notificationsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
});

export const {
  selectAll: selectNotifications,
  selectEntities: selectNotificationsEntities,
  selectById: selectNotificationById,
} = notificationsAdapter.getSelectors((state) => state.notifications);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: notificationsAdapter.getInitialState({
    pagination: {
      currentPage: 1,
      nextPage: null,
      previousPage: null,
      rowsPerPage: 20,
      totalPages: 1,
      totalRowCount: 0,
    },
    notificationModal: {
      data: null,
      show: false,
    },
    notificationInfosModal: {
      data: null,
      show: false,
    },
    selectedNotification: null,
    users: [],
    filters: { type: null },
  }),
  reducers: {
    openNotificationModal: (state) => {
      state.notificationModal = { show: true, data: null };
    },
    closeNotificationModal: (state) => {
      state.notificationModal = { data: null, show: false };
    },

    openNotificationInfosModal: (state, { payload }) => {
      state.notificationInfosModal = { show: true, data: payload };
    },
    closeNotificationInfosModal: (state) => {
      state.notificationInfosModal = { data: null, show: false };
    },

    clearUsers: (state) => {
      state.users = [];
    },

    setUserType: (state, { payload }) => {
      state.filters.type = payload;
    },
  },
  extraReducers: {
    [getNotifications.fulfilled]: (state, { payload }) => {
      notificationsAdapter.setAll(state, payload.data);
      state.pagination = {
        currentPage: payload.currentPage,
        nextPage: payload.nextPage,
        previousPage: payload.previousPage,
        rowsPerPage: payload.rowsPerPage,
        totalPages: payload.totalPages,
        totalRowCount: payload.totalRowCount,
      };
    },

    [createNotification.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        notificationsAdapter.addOne(state, payload.data);
        state.pagination.totalRowCount += 1;
        state.pagination.rowsPerPage += 1;
      }
    },

    [updateNotificationStatus.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        notificationsAdapter.updateOne(state, {
          id: payload.data.id,
          changes: payload.data,
        });
      }
    },

    [deleteNotification.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        notificationsAdapter.removeOne(state, payload.data.id);
      }
      return state;
    },

    [getUsers.fulfilled]: (state, { payload }) => {
      state.users = payload.data;
    },

    [getNotificationInfos.fulfilled]: (state, { payload }) => {
      if (payload.success) {
        state.selectedNotification = payload.data;
      }
    },
  },
});

export const {
  openNotificationModal,
  closeNotificationModal,
  openNotificationInfosModal,
  closeNotificationInfosModal,
  clearUsers,
  setUserType,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
